import React from 'react';
import styled from 'styled-components';
import {media, styles} from 'global.config';
import GeneralContainer from 'components/Container';
import CurvedBorder from 'components/CurvedBorder';
import CircleLogo from 'images/email-marketing-software-circle-logo.svg';
import NespressoLogo from 'images/email-marketing-software-nespresso-logo.svg';
import WarnerBrosLogo from 'images/email-marketing-software-warnerbros-logo.svg';
import AmazonLogo from 'images/email-marketing-software-amazon-logo.svg';
import PoloniexLogo from 'images/email-marketing-software-poloniex-logo.png';
import {useI18next} from 'gatsby-plugin-react-i18next';

export const Section = styled.section`
  overflow: hidden;
  width: 100%;
`;

export const Content = styled.div`
  margin-top: -10px;
  background: #fff;
`;

export const Logo = styled.img`
  width: 125px !important;
  height: auto;
  @media ${media.mobile} {
    margin: 30px 0 !important;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Container = styled(GeneralContainer)`
  padding: 0 ${styles.containerPadding} 60px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 10%;
    right: 10%;
    border-bottom: 1px solid #e5e5e5;
  }
  @media ${media.tablet} {
    padding-bottom: 5px;
    ${Logo} {
      display: inline-block;
      margin: 0 15px 30px;
    }
  }
  @media ${media.mobile} {
    padding-bottom: 5px;
    ${Logo} {
      display: inline-block;
      margin: 0 15px 30px;
    }
  }
`;

export const Logos = styled.div`
  margin: 0 0 15px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  @media ${media.mobile} {
    flex-direction: column;
  }
`;

export const SubHeader = styled.p`
  position: relative;
  top: -2vw;
  color: #b4b2b8;
  text-align: center;
  font-size: 14px;
  line-height: 34px;
  margin: 0;
  @media ${media.tablet} {
    top: 0;
    padding-bottom: 30px;
  }
  @media ${media.mobile} {
    padding: 30px 0;
  }
`;

export default () => {
  const {t} = useI18next();
  return (
    <Section>
      <CurvedBorder />
      <Content>
        <Container>
          <SubHeader>{t('Trusted by the Best')}</SubHeader>
          <Logos>
            <Logo
              src={CircleLogo}
              alt={t('Email marketing software used by Circle')}
              title={t('Email marketing software used by Circle')}
            />
            <Logo
              src={PoloniexLogo}
              alt={t('Email marketing software used by Poloniex')}
              title={t('Email marketing software used by Poloniex')}
            />
            <Logo
              src={AmazonLogo}
              alt={t('Email marketing software used by Amazon')}
              title={t('Email marketing software used by Amazon')}
            />
            <Logo
              src={NespressoLogo}
              alt={t('Email marketing software used by Nespresso')}
              title={t('Email marketing software used by Nespresso')}
            />
            <Logo
              src={WarnerBrosLogo}
              alt={t('Email marketing used by Warner bros')}
              title={t('Email marketing used by Warner bros')}
            />
          </Logos>
        </Container>
      </Content>
    </Section>
  );
};
